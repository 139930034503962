.presentation-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #000;

  .page-content{
    position: relative;
    width: 100%;
    //padding-top: 56.25%;
    overflow: hidden;
    background: #FFF;
    z-index: 2;
    aspect-ratio: 16 / 9;

    .view-lesson-content-container{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .view-lesson-content-container {
    position: relative;

    .exercise-toolbar {
      position: absolute;
    }

    &.lesson-presentation-layout{
      .presentation-action {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding: 0.5em;
        background: rgba(0, 0, 0, 0.01);

        &:hover {
          background: rgba(0, 0, 0, 0.1);

          .ant-btn {
            background: #FFF;
          }
        }

        &.left-action {
          left: 0
        }

        &.right-action {
          right: 0;
        }
      }
    }
  }

  .lesson-section{
    height: 100%;

    .lesson-section-container{
      height: 100%;
      display: flex;

      .content-group{
        height: 100%;

        .question {
          width: 100%;
          height: 100%;

          .question-content{
            height: 100%;
            overflow-y: auto;

            .resource-comps {
              position: relative;
              height: 100%;

              .fullscreen{
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }


}

