.gstudy-confirm-modal {
  .ant-modal-confirm-btns {
    display: none !important;
  }
}

.ant-modal.custom-modal{
  &.full-screen-modal{
    min-width: 900px;
  }
}


.custom-confirm-modal.ant-modal {
  min-width: 480px;
  max-width: calc(100vw - 20px);

  .ant-modal-confirm-body {
    .ant-modal-confirm-body > .anticon {
      font-size: 24px;
    }

    .ant-modal-confirm-title {
      color: #ff4d4f;
      font-size: 24px;
    }

    .ant-modal-confirm-content {
      font-size: 18px;
    }
  }

}


.ant-modal-wrap.custom-modal {
  .ant-modal-header {
    background: #f9f8fb;
  }

  &.screen-xs {
    > .ant-modal {
      max-width: calc(100% - 16px) !important;
    }
  }

  &.screen-sm {
    > .ant-modal {
      max-width: calc(100% - 24px) !important;
    }
  }

  &.screen-md {
    > .ant-modal {
      max-width: calc(100% - 32px) !important;
    }
  }

  &.screen-lg {
    > .ant-modal {
      max-width: calc(100% - 40px) !important;
    }
  }

  &.medium-modal {
    .ant-modal {
      max-width: 760px;
    }
  }

  &.large-modal,
  &.full-screen-modal {
    .ant-modal {
      max-width: 900px;
    }
  }

  &.full-size-modal {
    .ant-modal {
      width: 1300px !important;
      max-width: calc(100vw - 100px);
    }

    .ant-tabs {
      .ant-tabs-nav {
        .ant-tabs-tab {
          padding-left: 0px;
          font-size: 15px;
        }
      }
    }
  }

  &.empty-modal {
    .ant-modal-header {
      display: none !important;
    }

    .ant-modal-body {
      padding: 0px !important;

      .content-modal-container {
        padding: 0px !important;
      }
    }
  }

  .ant-modal {
    width: 100% !important;
    max-width: 600px;

    .ant-modal-content {
      border-radius: 1em;
      padding: 0;

      .ant-modal-header {
        border-radius: 1em 1em 0em 0em;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 0;

        .ant-modal-title {
          //color: #6610f2;
        }
      }

      .ant-modal-body {
        padding: 0;
      }
    }
  }
}


.ant-modal-wrap.fullscreen-modal {
  &.medium-modal {
    .ant-modal {
      max-width: 760px !important;
    }
  }

  &.large-modal {
    .ant-modal {
      max-width: 900px !important;
    }
  }

  .ant-modal {
    width: 100% !important;
    max-width: 1152px;
  }
}