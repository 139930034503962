@mixin xs() {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin sm() {
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin md() {
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    @content;
  }
}

@mixin lg() {
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    @content;
  }
}

@mixin xl() {
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    @content;
  }

}

@mixin xxl() {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

.screen-content {
  display: none;

  &.content-xs {
    @media only screen and (max-width: 575.9px) {
      display: block;
    }
  }

  &.content-sm {
    @media only screen and (max-width: 767.9px) and (min-width: 576px) {
      display: block;
    }
  }


  &.content-md {
    @media only screen and (max-width: 991.9px) and (min-width: 768px) {
      display: block;
    }
  }

  &.content-lg {
    @media only screen and (max-width: 1199.9px) and (min-width: 992px) {
      display: block;
    }
  }


  &.content-xl {
    @media only screen and (max-width: 1600px) and (min-width: 1200px) {
      display: block;
    }
  }

  &.content-xxl {
    @media only screen and (min-width: 1600px) {
      display: block;
    }
  }
}


