//custom antd style
@import "components/dropdown";
@import "components/card";
@import "components/modal";
@import "components/tabs";
@import "components/popup";

// add content style
@import "content/compose";
@import "content/index";

// add responsive style
@import "responsive";

:root{
  --x-font-sans-serif: 'Montserrat', sans-serif !important;
  --app-font-size: 14px;
  --default-menu-size: 16px;
  --default-sub-menu-size: 15px;
  //--primary-color: #884ffb;
  //--primary-background-color: #e2d8f9;
}


html,
body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-style: normal;
  font-size: var(--app-font-size, 14px);
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  color: #212529;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  #root {
    width: 100%;
    min-height: 100vh;
  }
}



a {
  color: inherit;
  text-decoration: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.ant-modal-wrap .modal-application {
  width: 1440px !important;
}

.hint-text{
  color: red;
  font-size: 13px;
}

.anticon > svg{
  vertical-align: baseline;
}


.submit-container{
  margin-top: 20px;
}

.mt-3{
  margin-top: 3em;
}

.scroll-container,
.scrollable-container{
  padding-right: 5px;
  margin-right: 5px;
  overflow-y:auto;

  &.scroll-flex{
    padding-right: 10px;
    margin-right: -20px;
  }

  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.is-iframe .no-iframe{
  display: none;
}

.d-flex{
  display: flex;
}


.hide-scrollbar{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}