@import "layout/presentation-layout";

.lesson-layout{
  .lesson-header{
    text-align: left;
    font-size: 200%;

    h1{
      font-size: 200%;
    }
  }

  .lesson-content{
    padding: var(--cms-padding-content, 0em);
  }
}

.lesson-template-content{
  h1{
    font-size: 200%;
  }

  h2{
    font-size: 125%;
  }

  h3{
    font-size: 115%;
  }

  h4{
    font-size: 100%;
  }

  blockquote{
    border-left: 2px solid #ccc;
    padding-left: 1em;
    font-size: 90%;
  }

  ul{
    margin-left: 1em;
  }
}
