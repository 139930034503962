.ant-popover.user-info-popup {
  .ant-popover-inner{
    border-radius: 1em;
  }

  .ant-popover-title{
    text-align: center;
    //padding: 1em;
    min-width: 250px;
  }

  .ant-popover-inner-content{
    //padding: 1em;
    font-size: 16px;
  }

}

.ant-popover.menu-group-content {
  .ant-popover-inner{
    padding: 0.5em;
    border-radius: 1em;
  }
  .ant-popover-inner-content{
    font-size: 16px;
  }
}