.compose-lesson-bank{
  .ant-drawer-body{
    background: #f0f2f5;
    padding: 10px;

    .resource-selectable{
      border: 1px solid #ccc;
    }

    .select-lesson-resource{
      .resource-item{
        border: 1px solid #ccc;
        background: #FFF;
      }
    }
  }
}

.chat-box-message{
  .ant-drawer-body{
    background: #f0f2f5;
    padding: 15px;
  }
}


//.cms-section{
//  outline: 6px dashed red !important;
//}
//
//.cms-question{
//  outline: 4px dotted blue !important;
//}
//
//
//.cms-comp{
//  border: 2px solid green !important;
//}
